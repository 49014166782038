import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import RadioField from "./elements/radio"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import { getUser } from "@starberry/myaccount-website-utils"
import FileField from "./elements/file"
import $ from "jquery"
import axios from "axios"
import * as qs from "query-string"
import { useStaticQuery, graphql } from "gatsby"

function MySimpleForm(props) {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        careers(publicationState: LIVE) {
          Job_Title
        }
      }
    }
  `)

  const dataList = data?.glstrapi?.careers
  let careerList = []
  dataList?.map(data => {
    return careerList.push(data.Job_Title)
  })

  const userData = getUser()

  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)
  const [disable, setDisable] = useState(false)

  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")
  const [file, setFile] = useState("") // storing the uploaded file
  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  const fields = [
    {
      element: "config",
      formname: props?.formName ? props?.formName : "Send CV",
      form_type: "careers",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "careers_user",
      email_temp_admin: "careers_admin",
      email_subject_user:
        "Thank you for submitting your Job Application to DNG",
      email_subject_admin: "New Application Received for Job Ad",
      email_server_func: "careers",
      event_tracking: "careers",
      page_url: "/general-enquiry",
    },
    {
      grpmd: "12",
      label: "Name",
      placeholder: "",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      labelClass: "content_b-18",
      defaultValue: userData?.name || ``,
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
      defaultValue: userData?.email || ``,
    },
    {
      grpmd: "12",
      label: "Telephone Number",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
      defaultValue: userData?.mobile || ``,
    },
    {
      grpmd: "12",
      label: "Job Role:",
      placeholder: "Job Role",
      name: "job_role",
      event_tracking_decider: "type",
      element: "select",
      required: false,
      values: careerList,
      // class: "md-12 select-input",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "CV/Resume",
      placeholder: "Click to attach",
      name: "file",
      type: "file",
      element: "file",
      required: true,
      accept: ".pdf,.doc,.docx",
      fieldClass:
        "form-control-browsefile custom custom-file form-control attach-file-wrap",
      labelClass: "content_b-18",
    },
    {
      type: "hidden",
      element: "input",
      name: "cvname",
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Write any information here...",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows: "3",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "w-md-100 mtop-btn  btn btn-yellow",
      labelClass: "content_b-18",
    },
    {
      text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a>, <a href="/privacy-policy/">Privacy</a> & <a href="/cookie-policy/">Cookie Policy</a> ',

      element: "html",
      class: "terms",
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef,
    },
  ]

  const handleAttachment = event => {
    const fileItem = event.target.files[0] // accesing file

    var d = document.getElementById("custom-file")
    //alert(d.value);
    $(".form-control-browsefile .custom-file-label").html(
      d.value.replace(/C:\\fakepath\\/, "")
    )

    setFile(fileItem) // storing file

    if (fileItem == "" || fileItem == null || fileItem == undefined) {
      $(".form-control-browsefile").addClass("fileerror")
      $(".form-control-browsefile").removeClass("fileadded")
    } else {
      $(".form-control-browsefile").removeClass("fileerror")
      $(".form-control-browsefile").addClass("fileadded")
    }

    
  }

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== "") {
      const processFromData = async () => {
        formvalues["g_recaptcha_response"] = token

        let formData = new FormData()

        // if (formvalues?.files) {
        //   formData.append(
        //     "files.attachment",
        //     formvalues.files,
        //     formvalues.files.name
        //   ) // appending file
        // }
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }

        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name,
        })

        formvalues["name"] = formvalues.name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin
        formData.append("data", JSON.stringify(formvalues))
        // console.log(formData)
        const isBlocked = formvalues.email == "markkearney2099@gmail.com";
        if (isBlocked) {
          setThankyou(true);
          return;
        } else {
          postFormData(formData).then(async apiRes => {
            // console.log(apiRes,"teess")
            // if(apiRes && apiRes.id) {
            //   if (apiRes?.attachment[0]?.url) {
            //     // If image from externall url, use that
            //     if (apiRes.attachment[0].url.match(/(http|https):\/\//g)) {
            //       formvalues['file'] = `${apiRes.attachment[0].url}`;
            //       formvalues['cvname'] = `${apiRes.attachment[0].url}`;
            //     } else { // or we consider it is loading from api url
            //       formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
            //       formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
            //     }
            //   }
            // }
            if (apiRes?.form?.attachment && apiRes?.form?.attachment?.length > 0) {
              if (apiRes?.form?.attachment[0]?.url) {
                // If image from externall url, use that
                if (apiRes?.form?.attachment[0]?.url?.match(/(http|https):\/\//g)) {
                  formvalues['file'] = `${apiRes?.form?.attachment[0]?.url}`;
                  formvalues['cvname'] = `${apiRes?.form?.attachment[0]?.url}`;
                } else { // or we consider it is loading from api url
                  formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes?.form?.attachment[0]?.url}`;
                  formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes?.form?.attachment[0]?.url}`;
                }
              }
            }
            if (apiRes?.form?.attachment?.url) {
              // If image from externall url, use that
              if (apiRes?.form?.attachment?.url?.match(/(http|https):\/\//g)) {
                formvalues["file"] = `${apiRes?.form?.attachment?.url}`
                formvalues["cvname"] = `${apiRes?.form?.attachment?.url}`
              } else {
                // or we consider it is loading from api url
                formvalues["file"] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes?.form?.attachment?.url}`
                formvalues["cvname"] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes?.form?.attachment?.url}`
              }
            }


            window.grecaptcha.reset()
            //console.log("formvalues ",formvalues);
            // lets send mail
            await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
              method: `POST`,
              mode: "no-cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: qs.stringify(formvalues),
            })

            // const axiosOptions_email = {
            //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
            //   method: "post",
            //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
            //   data: qs.stringify(formvalues),
            // }

            // axios(axiosOptions_email)
            //   .then(response => {
            //     console.log('mail sent!')
            //   })
            //   .catch(err =>
            //     console.log(err)
            //   );
          })
          // const axiosOptions_netlify = {
          //   url: fields[0].page_url,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_netlify)
          //   .then(response => {
          //     console.log('data stored')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );
          const url = typeof window !== "undefined" ? window.location.href : ""
          if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
            fields[0].formname = "Get mortgage help"
          }
          // tracking event
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "formSubmit",
            formType: "form-" + fields[0].event_tracking,
            formId: "form-" + fields[0].event_tracking,
            formName: fields[0].formname,
            formLabel: fields[0].formname,
          })
          setFile("")
          $(".form-control-browsefile").removeClass("fileadded")
          setShowerror(false)
          setThankyou(true)

          myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    const form = event.currentTarget

    if (file == "" || file == null) {
      $(".form-control-browsefile").addClass("fileerror")
    } else {
      $(".form-control-browsefile").removeClass("fileerror")
    }


    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      event.preventDefault()
      setDisable(true)
      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g_recaptcha_response"] = token
      json["files"] = file
      // setFile(file)
      setFormvalues(json)

      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  return (
    <div className="form stbform careersform" id="contactform">
      <div ref={myRef} />

      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      {showthankyou && (
        <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>
      )}
      <Form
        className="contact-form form-w-label"
        enctype='multipart/form-data'
        name={fields[0].formname}
        action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input
          type="hidden"
          name="to_email_id"
          value={props.toemail}
        />
        <input type="hidden" name="jobtitle" value={props.Job_Title} />
        <input type="hidden" name="shareurl" value={props.shareurl} />
        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                type={field.type}
                fieldClass={field.class}
                placeholder={field.placeholder}
                label={field.label}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
                defaultValue={field?.defaultValue}
              />
            )
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
                selected={props.Job_Title}
              />
            )
          }
          if ("file" === field.element) {
            return (
              <FileField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                label={field.label}
                type={field.type}
                fieldClass={field.fieldClass}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                key={`${field.element}~${index}`}
                accept={field.accept}
                handlechange={handleAttachment}
                required={field.required}
                file={file}
              />
            )
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("radio" === field.element) {
            return (
              <RadioField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                checked={field.checked}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
                lastchild={field.lastchild}
              />
            )
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            )
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            )
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
                disabled={disable}
              />
            )
          }
        })}
      </Form>
    </div>
  )
}

const ContactForm = props => <MySimpleForm {...props} />

export default ContactForm
